//////////////////////////////////////////////////////////
// 1. Media Query
//////////////////////////////////////////////////////////

@mixin media( $breakpoint ) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
       }
    }
}



//////////////////////////////////////////////////////////
// 2. rem
//////////////////////////////////////////////////////////

@mixin rem($size) {
    font-size: $size + px;
    font-size: ($size / 10) + rem;
}
