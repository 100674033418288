.footer {
  .footer-img {
    line-height: 0;
    margin-bottom: -2px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .footer-info {
    background-color: $primary-color;
    text-align: center;
    color: #fff;
    padding: 20px;
    @include media(PC){
      padding: 30px 0;
    }
    p {
      font-size: 1.8rem;
      line-height: 1;
      @include media(PC){
        font-size: 2.4rem;
        margin-bottom: 10px;
      }
      span {
        font-size: 1.0rem;
        @include media(PC){
          font-size: 1.4rem;
        }
      }
    }
  }
  .footer-nav {
    display: none;
    @include media(PC){
      display: block;
      padding: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        li {
          margin: 0 30px;
          a {
            font-size: 1.4rem;
            text-decoration: none;
            color: #000;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .copyright {
    text-align: center;
    padding: 20px 20px 70px;
    font-size: 0.9rem;
    @include media(PC){
      padding: 30px;
      font-size: 1.2rem;
    }
  }
}



.pagetop {
  position: fixed;
  bottom: 60px;
  right: 20px;
  z-index: 999999;
  @include media(PC){
    right: 60px;
  }
  a {

    display: block;
    width: 50px;
    height: 80px;
    background-image: url(./assets/img/back.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    transition: all .2s ease;
    @include media(PC){
      width: 80px;
      height: 100px;
    }
    &:hover {
      background-image: url(./assets/img/back_on.png);
    }
  }
}


.fixednav {
  position: fixed;
  bottom:0;
  width: 100%;
  left:0;
  z-index: 999999;
  background-color: #004D29;
  @include media(PC){
    display: none;
  }
  ul {
    display: flex;
    justify-content: space-between;
    li {
      width: 50%;
      background-color: $primary-color;
      &:first-child {
        border-right: 2px solid #004D29;
      }
      a {
        padding: 15px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
        text-decoration: none;
        color: #fff;
        font-size: 1.6rem;
        line-height: 1;
        font-weight: bold;
        i {
          margin-right: 10px;
          font-size: 2.0rem;
        }
      }
    }
  }
}
