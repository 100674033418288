#price {
  padding-top: 100px;
  @include media(TB){
    padding-top: 50px;
  }
  .price-title {
    text-align: center;
    margin-bottom: 20px;
    @include media(TB){
      margin-bottom: 80px;
    }
    img {
      width: 50%;
      max-width: 200px;
      @include media(TB){
        width: auto;
        max-width: 100%;
      }
    }
  }
.price-menu {
  background-color: #FAF7F0;
  margin: 0 15px 50px;
  padding: 30px 20px;
  @include media(TB){
    padding: 0;
    width: $common-width;
    min-width: $common-width;
    margin: 0 auto 80px;
  }
  .inr {
    @include media(TB){
      padding: 50px 80px;
    }
  }
  .copy {
    margin-bottom: 20px;
    @include media(TB){
      margin-bottom: 50px;
    }
    p {
      text-align: center;
      font-size: 1.8rem;
      color: $primary-color;
      @include media(TB){
        font-size: 2.4rem;
        line-height: 2;
      }
      span {
        background: linear-gradient(transparent 70%, #FDF9C6 70%);
      }
    }
  }
  .list {
    @include media(TB){
      width: 600px;
      margin: 0 auto 50px;
    }
    dl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.85rem;
      color: $primary-color;
      background-image: url(./assets/img/bg-dot.png);
      background-repeat: repeat-x;
      background-position: center center;
      background-size: auto 3px;
      margin-bottom: 10px;
      @include media(TB){
        font-size: 2.6rem;
        margin-bottom: 20px;
      }
      dt, dd {
        background: #FAF7F0;
        @include media(TB){
          padding: 0 5px;
        }
      }
      dd {
        font-family: $heading-font;
      }
    }
  }
  .text {
    margin-bottom: 30px;
    @include media(TB){
      width: 800px;
      margin: 0 auto 80px;
    }
    p {
      color: #231815;
      font-size: 1.05rem;
      line-height: 2;
      @include media(TB){
        font-size: 1.4rem;
        letter-spacing: .05em;
      }
    }
  }
  .list2 {
    @include media(TB){
      width: 550px;
      margin: -20px auto 0;
    }
    dl {
      display: flex;
      flex-direction: column;
      font-size: 1.5rem;
      color: $primary-color;
      margin-bottom: 10px;
      @include media(TB){
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 2.0rem;
        margin-bottom: 20px;
      }
      dt {
        width: 120px;
        display: flex;
        justify-content: space-between;
        @include media(TB){
          width: 160px;
          margin-right: 10px;
        }
      }
      dd {
        span {
          background: linear-gradient(transparent 70%, #FDF9C6 70%);
        }
      }
    }
  }
}
}
