@charset "UTF-8";
/*! sanitize.css v7.0.3 | CC0 License | github.com/csstools/sanitize.css */
/* Document
 * ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add border box sizing in all browsers (opinionated).
 */
@import url("https://fonts.googleapis.com/css?family=Questrial");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700");
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: border-box;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Use the default user interface font in all browsers (opinionated).
 * 3. Correct the line height in all browsers.
 * 4. Use a 4-space tab width in all browsers (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */
html {
  cursor: default;
  /* 1 */
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* 2 */
  line-height: 1.15;
  /* 3 */
  -moz-tab-size: 4;
  /* 4 */
  tab-size: 4;
  /* 4 */
  -ms-text-size-adjust: 100%;
  /* 5 */
  -webkit-text-size-adjust: 100%;
  /* 5 */
  word-break: break-word;
  /* 6 */ }

/* Sections
 * ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
 * ========================================================================== */
/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Add the correct display in IE.
 */
main {
  display: block; }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none; }

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: Menlo, Consolas, Roboto Mono, Ubuntu Monospace, Noto Mono, Oxygen Mono, Liberation Mono, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics
 * ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Add the correct text decoration in Edge, IE, Opera, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: Menlo, Consolas, Roboto Mono, Ubuntu Monospace, Noto Mono, Oxygen Mono, Liberation Mono, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/*
 * Remove the text shadow on text selections in Firefox 61- (opinionated).
 * 1. Restore the coloring undone by defining the text shadow
 *    in all browsers (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000;
  /* 1 */
  text-shadow: none; }

/* Embedded content
 * ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg:not([fill]) {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Tabular data
 * ========================================================================== */
/**
 * Collapse border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse; }

/* Forms
 * ========================================================================== */
/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * Remove the margin in Safari.
 */
button,
input,
select {
  margin: 0; }

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge, Firefox, and IE.
 */
button {
  overflow: visible;
  /* 1 */
  text-transform: none;
  /* 2 */ }

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
 * Show the overflow in Edge and IE.
 */
input {
  overflow: visible; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in Edge and IE.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none; }

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  margin: 0;
  /* 1 */
  overflow: auto;
  /* 2 */
  resize: vertical;
  /* 3 */ }

/**
 * Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  padding: 0; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto; }

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText; }

/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge and IE.
 */
details {
  display: block; }

/*
 * Add the correct styles in Edge, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content; }

dialog:not([open]) {
  display: none; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/* User interaction
 * ========================================================================== */
/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
  /* 2 */ }

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/* Accessibility
 * ========================================================================== */
/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */
[aria-disabled="true"],
[disabled] {
  cursor: not-allowed; }

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

iframe {
  border: none !important; }

html, body {
  font-size: 62.5%;
  font-family: "Noto Sans JP", "Helvetica Neue", Helvetica, Arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  color: #131313;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased; }
  @media screen and (min-width: 769px) {
    html, body {
      min-width: 1200px; } }

::selection {
  background: #2C653B;
  color: #fff;
  text-shadow: none; }

.sp-show {
  display: inherit; }
  @media screen and (min-width: 769px) {
    .sp-show {
      display: none; } }

.pc-show {
  display: none; }
  @media screen and (min-width: 769px) {
    .pc-show {
      display: inherit; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.header {
  padding: 10px 15px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;
  background-color: #fff; }
  @media screen and (min-width: 769px) {
    .header {
      position: relative;
      min-width: 1200px;
      width: 1200px;
      margin: 0 auto;
      padding: 20px 100px 35px; } }
  .header .logo {
    width: 250px; }
    @media screen and (min-width: 769px) {
      .header .logo {
        width: 430px;
        margin-top: 20px;
        margin-left: -100px; } }
    .header .logo a {
      display: block; }
      .header .logo a dl {
        display: flex;
        align-items: center; }
        @media screen and (min-width: 769px) {
          .header .logo a dl {
            align-items: flex-start; } }
        .header .logo a dl dt {
          width: 45px;
          line-height: 1; }
          @media screen and (min-width: 769px) {
            .header .logo a dl dt {
              width: auto;
              margin-bottom: 10px; } }
          .header .logo a dl dt img {
            width: 100%;
            height: auto; }
        .header .logo a dl dd {
          width: 200px;
          line-height: 1; }
          @media screen and (min-width: 769px) {
            .header .logo a dl dd {
              width: auto; } }
          .header .logo a dl dd img {
            width: 100%;
            height: auto; }
  .header .gnavWrap {
    display: none;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 300px;
    max-width: 78%;
    background-color: #2C653B;
    font-size: 1.8rem;
    z-index: 99999; }
    @media screen and (min-width: 769px) {
      .header .gnavWrap {
        display: inherit;
        max-width: 100%;
        width: 900px;
        margin: 0 auto;
        top: 80px;
        right: 0;
        left: 0;
        background-color: rgba(44, 101, 59, 0);
        z-index: 99999; } }
    .header .gnavWrap .gnav {
      padding: 60px 20px 40px; }
      @media screen and (min-width: 769px) {
        .header .gnavWrap .gnav {
          padding: 0; } }
      @media screen and (min-width: 769px) {
        .header .gnavWrap .gnav ul {
          display: flex;
          margin-left: -30px; } }
      .header .gnavWrap .gnav li {
        padding: 10px 20px;
        border-bottom: 1px solid rgba(153, 186, 61, 0.2); }
        @media screen and (min-width: 769px) {
          .header .gnavWrap .gnav li {
            border-bottom: 0px solid rgba(153, 186, 61, 0);
            padding: 0;
            position: relative; }
            .header .gnavWrap .gnav li:after {
              content: "";
              width: 1;
              height: 40px;
              border-right: 1px solid #7F4F21;
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              transform: rotate(20deg);
              margin: auto; } }
        .header .gnavWrap .gnav li a {
          text-decoration: none;
          color: #fff;
          display: block; }
          @media screen and (min-width: 769px) {
            .header .gnavWrap .gnav li a {
              color: #7F4F21;
              font-size: 1.4rem;
              padding: 20px 35px;
              text-align: center;
              transition: all .2s ease;
              opacity: 1; }
              .header .gnavWrap .gnav li a:hover {
                opacity: .5; } }
          .header .gnavWrap .gnav li a span {
            display: none;
            font-family: "Questrial", "Helvetica Neue", Helvetica, Arial, serif;
            font-size: 1.1rem;
            color: #2C653B;
            letter-spacing: .1em; }
            @media screen and (min-width: 769px) {
              .header .gnavWrap .gnav li a span {
                display: inherit; } }
  .header .header-contact {
    display: none; }
    @media screen and (min-width: 769px) {
      .header .header-contact {
        display: inherit;
        position: absolute;
        right: 0;
        top: 25px;
        transition: all .2s ease;
        opacity: 1; }
        .header .header-contact:hover {
          opacity: .8; } }

.menu {
  position: absolute;
  right: 15px;
  top: 25px;
  height: 25px;
  width: 30px;
  z-index: 99999; }
  .menu span {
    position: absolute;
    bottom: 7px;
    margin: 0;
    padding: 0; }
    .menu span img {
      width: 100%;
      height: auto; }
  @media screen and (min-width: 769px) {
    .menu {
      display: none; } }

.menu .menu-line {
  position: absolute;
  width: 30px;
  left: 0px;
  height: 5px;
  background: #2C653B; }

.menu .menu-line:nth-of-type(1) {
  top: 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.menu .menu-line:nth-of-type(2) {
  top: 10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.menu .menu-line:nth-of-type(3) {
  top: 20px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.menu.open .menu-line {
  background: #fff; }

.menu.open .menu-line:nth-of-type(1) {
  top: 10px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.menu.open .menu-line:nth-of-type(2) {
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

.menu.open .menu-line:nth-of-type(3) {
  top: 10px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

@media screen and (min-width: 769px) {
  .homepage .header:before {
    content: "";
    width: 500px;
    height: 200px;
    display: block;
    background-image: url(./assets/img/topkazari.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    position: absolute;
    left: -270px;
    top: 0px; } }

.homepage .logo {
  width: 250px; }
  @media screen and (min-width: 769px) {
    .homepage .logo {
      width: 350px;
      margin: 0 auto; } }
  .homepage .logo a {
    display: block; }
    .homepage .logo a dl {
      display: flex;
      align-items: center; }
      @media screen and (min-width: 769px) {
        .homepage .logo a dl {
          flex-direction: column; } }
      .homepage .logo a dl dt {
        width: 45px;
        line-height: 1; }
        @media screen and (min-width: 769px) {
          .homepage .logo a dl dt {
            width: auto;
            margin-bottom: 10px; } }
        .homepage .logo a dl dt img {
          width: 100%;
          height: auto; }
      .homepage .logo a dl dd {
        width: 200px;
        line-height: 1; }
        @media screen and (min-width: 769px) {
          .homepage .logo a dl dd {
            width: auto; } }
        .homepage .logo a dl dd img {
          width: 100%;
          height: auto; }

@media screen and (min-width: 769px) {
  .homepage .gnavWrap {
    display: inherit;
    max-width: 100%;
    width: 900px;
    margin: 0 auto;
    top: 720px;
    right: 0;
    left: 0;
    z-index: 99999;
    background-color: rgba(44, 101, 59, 0); } }

.footer .footer-img {
  line-height: 0;
  margin-bottom: -2px; }
  .footer .footer-img img {
    width: 100%;
    height: auto; }

.footer .footer-info {
  background-color: #2C653B;
  text-align: center;
  color: #fff;
  padding: 20px; }
  @media screen and (min-width: 769px) {
    .footer .footer-info {
      padding: 30px 0; } }
  .footer .footer-info p {
    font-size: 1.8rem;
    line-height: 1; }
    @media screen and (min-width: 769px) {
      .footer .footer-info p {
        font-size: 2.4rem;
        margin-bottom: 10px; } }
    .footer .footer-info p span {
      font-size: 1.0rem; }
      @media screen and (min-width: 769px) {
        .footer .footer-info p span {
          font-size: 1.4rem; } }

.footer .footer-nav {
  display: none; }
  @media screen and (min-width: 769px) {
    .footer .footer-nav {
      display: block;
      padding: 30px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .footer .footer-nav ul {
        display: flex;
        justify-content: center;
        align-items: center; }
        .footer .footer-nav ul li {
          margin: 0 30px; }
          .footer .footer-nav ul li a {
            font-size: 1.4rem;
            text-decoration: none;
            color: #000; }
            .footer .footer-nav ul li a:hover {
              text-decoration: underline; } }

.footer .copyright {
  text-align: center;
  padding: 20px 20px 70px;
  font-size: 0.9rem; }
  @media screen and (min-width: 769px) {
    .footer .copyright {
      padding: 30px;
      font-size: 1.2rem; } }

.pagetop {
  position: fixed;
  bottom: 60px;
  right: 20px;
  z-index: 999999; }
  @media screen and (min-width: 769px) {
    .pagetop {
      right: 60px; } }
  .pagetop a {
    display: block;
    width: 50px;
    height: 80px;
    background-image: url(./assets/img/back.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    transition: all .2s ease; }
    @media screen and (min-width: 769px) {
      .pagetop a {
        width: 80px;
        height: 100px; } }
    .pagetop a:hover {
      background-image: url(./assets/img/back_on.png); }

.fixednav {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 999999;
  background-color: #004D29; }
  @media screen and (min-width: 769px) {
    .fixednav {
      display: none; } }
  .fixednav ul {
    display: flex;
    justify-content: space-between; }
    .fixednav ul li {
      width: 50%;
      background-color: #2C653B; }
      .fixednav ul li:first-child {
        border-right: 2px solid #004D29; }
      .fixednav ul li a {
        padding: 15px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
        text-decoration: none;
        color: #fff;
        font-size: 1.6rem;
        line-height: 1;
        font-weight: bold; }
        .fixednav ul li a i {
          margin-right: 10px;
          font-size: 2.0rem; }

@media screen and (min-width: 769px) {
  #index {
    min-width: 1200px; } }

#index .index-slider {
  position: relative;
  margin-top: 80px; }
  @media screen and (min-width: 769px) {
    #index .index-slider {
      margin-top: 0;
      min-width: 1200px;
      width: 100%; } }
  #index .index-slider .js-slider-col img {
    width: 100%;
    height: auto; }

#index .menu-bg {
  position: absolute;
  bottom: 3px;
  z-index: 99998;
  width: 100%;
  line-height: 1;
  vertical-align: bottom;
  margin-bottom: -2px; }
  @media screen and (min-width: 769px) {
    #index .menu-bg {
      bottom: 1px; } }
  #index .menu-bg img {
    width: 100%;
    height: auto;
    line-height: 0;
    vertical-align: bottom; }

#index .index-concept {
  position: relative;
  z-index: 99999; }
  @media screen and (min-width: 769px) {
    #index .index-concept {
      width: 1200px;
      min-width: 1200px;
      margin: 0 auto;
      padding: 100px 100px;
      position: relative;
      overflow: hidden; }
      #index .index-concept:after {
        content: "";
        width: 850px;
        height: 60px;
        margin-top: 40px;
        background-image: url(./assets/img/concept_kazari.png);
        background-repeat: no-repeat;
        display: block;
        background-size: 100% auto;
        position: absolute;
        bottom: 120px;
        right: -60px;
        z-index: -1; } }
  #index .index-concept .title {
    position: absolute;
    width: 80vw;
    top: -20vw;
    left: -8vw; }
    @media screen and (min-width: 769px) {
      #index .index-concept .title {
        width: auto;
        top: 20px;
        left: auto;
        right: -80px; } }
    #index .index-concept .title img {
      width: 100%;
      height: auto; }
  #index .index-concept .text {
    padding-top: 15vw; }
    @media screen and (min-width: 769px) {
      #index .index-concept .text {
        padding-top: 0; } }
    @media screen and (min-width: 769px) {
      #index .index-concept .text dl {
        display: flex;
        justify-content: space-between;
        align-items: center; } }
    @media screen and (min-width: 769px) {
      #index .index-concept .text dl dt {
        width: 420px; } }
    #index .index-concept .text dl dt:after {
      content: "";
      width: 100%;
      height: 10vw;
      margin-top: 40px;
      background-image: url(./assets/img/concept_kazari.png);
      background-repeat: no-repeat;
      display: block;
      background-size: 100% auto; }
      @media screen and (min-width: 769px) {
        #index .index-concept .text dl dt:after {
          display: none; } }
    #index .index-concept .text dl dt img {
      width: 65%;
      max-width: 300px;
      margin: 0 auto;
      display: block; }
      @media screen and (min-width: 769px) {
        #index .index-concept .text dl dt img {
          width: 100%;
          max-width: 100%; } }
    #index .index-concept .text dl dd {
      padding: 15px;
      margin-bottom: 30px; }
      @media screen and (min-width: 769px) {
        #index .index-concept .text dl dd {
          width: 500px;
          padding: 0;
          margin-bottom: 0;
          padding-top: 50px; } }
      #index .index-concept .text dl dd h3 {
        font-size: 1.8rem;
        color: #2C653B;
        font-weight: bold;
        margin-bottom: 15px; }
        @media screen and (min-width: 769px) {
          #index .index-concept .text dl dd h3 {
            font-size: 2.4rem;
            letter-spacing: -0.065em; } }
      #index .index-concept .text dl dd p {
        font-size: 1.2rem;
        color: #7F4F21;
        text-align: justify;
        text-justify: inter-ideograph; }
        @media screen and (min-width: 769px) {
          #index .index-concept .text dl dd p {
            font-size: 1.6rem; } }

#index .index-condition {
  background-color: #FAF7F0;
  position: relative;
  padding: 30vw 15px 50px; }
  @media screen and (min-width: 769px) {
    #index .index-condition {
      padding: 0;
      min-width: 1200px; } }
  @media screen and (min-width: 769px) {
    #index .index-condition .inr {
      width: 1200px;
      margin: 0 auto;
      padding: 100px;
      position: relative; } }
  #index .index-condition .title {
    position: absolute;
    width: 80vw;
    top: -10vw;
    left: -8vw; }
    @media screen and (min-width: 769px) {
      #index .index-condition .title {
        width: auto;
        top: -70px;
        left: 0; } }
    #index .index-condition .title img {
      width: 100%;
      height: auto; }
  #index .index-condition .text {
    text-align: center; }
    @media screen and (min-width: 769px) {
      #index .index-condition .text {
        padding-top: 80px;
        margin-bottom: 30px; } }
    #index .index-condition .text p {
      font-size: 1.6rem;
      color: #2C653B;
      line-height: 1.8; }
      @media screen and (min-width: 769px) {
        #index .index-condition .text p {
          font-size: 2.4rem; } }
      #index .index-condition .text p.strong {
        display: inline-block;
        font-size: 2.3rem;
        letter-spacing: -0.1em;
        margin-top: 5px;
        background: linear-gradient(transparent 70%, #FDF9C6 70%); }
        @media screen and (min-width: 769px) {
          #index .index-condition .text p.strong {
            font-size: 3.0rem; } }
  #index .index-condition .list {
    padding-top: 30px; }
    #index .index-condition .list ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-right: -2%; }
      @media screen and (min-width: 430px) {
        #index .index-condition .list ul {
          margin-left: -1%; } }
      #index .index-condition .list ul li {
        width: 48%;
        border: 1px solid #99BA3D;
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 .99C4 .445 4.444 0 5 0c.552 0 1 .45 1 .99v4.02C6 5.555 5.556 6 5 6c-.552 0-1-.45-1-.99V.99zm6 8c0-.546.444-.99 1-.99.552 0 1 .45 1 .99v4.02c0 .546-.444.99-1 .99-.552 0-1-.45-1-.99V8.99z' fill='%2399ba3d' fill-opacity='0.20' fill-rule='evenodd'/%3E%3C/svg%3E");
        background-repeat: repeat;
        margin-right: 2%;
        margin-bottom: 15px; }
        @media screen and (min-width: 430px) {
          #index .index-condition .list ul li {
            width: 24%;
            margin-right: 1%; } }
        #index .index-condition .list ul li a {
          display: flex;
          align-items: center;
          height: 100%;
          width: 100%;
          padding: 15px 10px;
          text-decoration: none;
          color: #2C653B;
          font-size: 1.5rem;
          letter-spacing: -0.05em;
          position: relative; }
          @media screen and (min-width: 430px) {
            #index .index-condition .list ul li a {
              padding: 15px 20px 15px 10px;
              font-size: 1.2rem; } }
          @media screen and (min-width: 769px) {
            #index .index-condition .list ul li a {
              font-size: 2.4rem;
              padding: 20px;
              padding-right: 40px;
              opacity: 1;
              transition: all .2s ease; }
              #index .index-condition .list ul li a:hover {
                opacity: .5; } }
          #index .index-condition .list ul li a:before {
            content: "";
            width: 15px;
            height: 15px;
            background-image: url(./assets/img/yajirushi.png);
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-position: center center;
            display: inline-block;
            position: absolute;
            right: 5px;
            top: 0;
            bottom: 0;
            margin: auto; }
            @media screen and (min-width: 769px) {
              #index .index-condition .list ul li a:before {
                width: 30px;
                height: 30px;
                right: 15px; } }
  #index .index-condition .copy {
    font-size: 2.2rem;
    text-align: center;
    padding-top: 10px;
    letter-spacing: -0.09em;
    color: #2C653B; }
    @media screen and (min-width: 769px) {
      #index .index-condition .copy {
        font-size: 3.0rem;
        padding-top: 30px; } }

#index .index-point {
  padding: 15px;
  background-image: url(./assets/img/takumiback.jpg);
  background-repeat: repeat-y;
  background-size: 100% auto;
  position: relative; }
  @media screen and (min-width: 769px) {
    #index .index-point {
      padding: 0;
      background-image: url(./assets/img/yakusokuback-100.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%; } }
  @media screen and (min-width: 769px) {
    #index .index-point .inr {
      width: 1200px;
      margin: 0 auto;
      position: relative; } }
  #index .index-point .title {
    width: 70%;
    max-width: 300px;
    position: absolute;
    top: 0;
    right: 0; }
    @media screen and (min-width: 769px) {
      #index .index-point .title {
        width: 490px;
        max-width: 100%; } }
    #index .index-point .title img {
      width: 100%;
      height: auto; }
  #index .index-point .list {
    padding-top: 28vw; }
    @media screen and (min-width: 769px) {
      #index .index-point .list {
        padding-top: 250px;
        padding-bottom: 30px; } }
    #index .index-point .list ul {
      padding: 0 8%; }
      @media screen and (min-width: 769px) {
        #index .index-point .list ul {
          padding: 0 100px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between; } }
      #index .index-point .list ul li {
        background-color: #fff;
        padding: 15px;
        margin-bottom: 20px;
        border: 1px solid #2C653B; }
        @media screen and (min-width: 769px) {
          #index .index-point .list ul li {
            width: 31%;
            padding: 20px;
            margin-bottom: 40px; } }
        #index .index-point .list ul li .no {
          margin-bottom: 30px;
          padding-left: 10px; }
          #index .index-point .list ul li .no img {
            width: 100%;
            height: auto; }
        #index .index-point .list ul li .copy {
          font-family: serif;
          color: #2C653B;
          text-align: center;
          font-size: 2.5rem;
          line-height: 1.2;
          margin-bottom: 15px; }
          @media screen and (min-width: 769px) {
            #index .index-point .list ul li .copy {
              font-size: 2.9rem;
              margin-bottom: 20px;
              letter-spacing: -0.1em; } }
        #index .index-point .list ul li .text {
          color: #7F4F21;
          font-size: 1.25rem;
          padding-bottom: 20px;
          text-align: justify;
          text-justify: inter-ideograph; }
          @media screen and (min-width: 769px) {
            #index .index-point .list ul li .text {
              font-size: 1.4rem;
              padding-bottom: 30px; } }

#index .index-blog {
  padding-bottom: 30px; }
  @media screen and (min-width: 769px) {
    #index .index-blog {
      padding-bottom: 60px; } }
  #index .index-blog .title {
    width: 40%;
    max-width: 300px;
    margin: 0 auto 20px;
    padding-top: 30px; }
    @media screen and (min-width: 769px) {
      #index .index-blog .title {
        padding-top: 70px;
        max-width: 250px;
        margin-bottom: 50px; } }
    #index .index-blog .title img {
      width: 100%;
      height: auto; }
  #index .index-blog .list ul {
    padding: 0 25px; }
    @media screen and (min-width: 769px) {
      #index .index-blog .list ul {
        padding: 0; } }
    #index .index-blog .list ul li a {
      display: block;
      padding: 5px;
      text-decoration: none;
      color: #000; }
      @media screen and (min-width: 769px) {
        #index .index-blog .list ul li a {
          padding: 0 20px;
          opacity: 1;
          transition: all .2s ease; }
          #index .index-blog .list ul li a:hover {
            opacity: .5; } }
      #index .index-blog .list ul li a .news-thumbnail {
        width: 100%;
        height: auto;
        margin-bottom: 10px; }
      #index .index-blog .list ul li a .txt .date {
        font-size: 1.0rem; }
        @media screen and (min-width: 769px) {
          #index .index-blog .list ul li a .txt .date {
            font-size: 1.2rem; } }
      #index .index-blog .list ul li a .txt .post-name {
        font-size: 1.3rem;
        text-decoration: underline;
        color: #2C653B;
        margin-bottom: 10px; }
        @media screen and (min-width: 769px) {
          #index .index-blog .list ul li a .txt .post-name {
            font-size: 1.6rem; } }
      #index .index-blog .list ul li a .txt .read {
        font-size: 1.1rem; }
        @media screen and (min-width: 769px) {
          #index .index-blog .list ul li a .txt .read {
            font-size: 1.4rem; } }
  #index .index-blog .list .slick-prev {
    left: 5px;
    z-index: 99999;
    font-size: 2.0rem;
    width: 30px;
    height: 30px; }
    @media screen and (min-width: 769px) {
      #index .index-blog .list .slick-prev {
        left: 80px;
        width: 50px;
        height: 50px; } }
  #index .index-blog .list .slick-next {
    right: 5px;
    z-index: 99999;
    width: 30px;
    height: 30px; }
    @media screen and (min-width: 769px) {
      #index .index-blog .list .slick-next {
        right: 80px;
        width: 50px;
        height: 50px; } }
  #index .index-blog .list .slick-next:before, #index .index-blog .list .slick-prev:before {
    font-family: slick;
    font-size: 30px;
    line-height: 1;
    color: #2C653B;
    opacity: .75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
    @media screen and (min-width: 769px) {
      #index .index-blog .list .slick-next:before, #index .index-blog .list .slick-prev:before {
        font-size: 50px; } }
  #index .index-blog .more {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 769px) {
      #index .index-blog .more {
        padding: 60px 0; } }
    #index .index-blog .more .btn {
      border: 1px solid #2C653B;
      width: 120px; }
      @media screen and (min-width: 769px) {
        #index .index-blog .more .btn {
          width: 200px;
          opacity: 1;
          transition: all .2s ease; }
          #index .index-blog .more .btn:hover {
            opacity: .5; } }
      #index .index-blog .more .btn a {
        width: 100%;
        display: block;
        font-size: 1.4rem;
        padding: 5px 5px;
        text-decoration: none;
        text-align: center;
        color: #2C653B;
        position: relative; }
        #index .index-blog .more .btn a:after {
          content: "\f105";
          position: absolute;
          font-family: fontAwesome;
          right: 10px; }

#index .index-sns {
  background-color: #FAF7F0;
  padding: 15px;
  margin-bottom: -70px; }
  @media screen and (min-width: 769px) {
    #index .index-sns {
      padding-bottom: 90px;
      margin-bottom: -150px; } }
  @media screen and (min-width: 769px) {
    #index .index-sns .inr {
      width: 1200px;
      margin: 40px auto;
      display: flex;
      align-items: center; } }
  @media screen and (min-width: 769px) {
    #index .index-sns .col2 {
      display: flex;
      flex-direction: row-reverse;
      margin: 0 auto; } }
  #index .index-sns .title {
    padding-top: 25px; }
    @media screen and (min-width: 769px) {
      #index .index-sns .title {
        padding-top: 0;
        margin-left: 250px; } }
    #index .index-sns .title img {
      max-width: 400px;
      width: 100%;
      margin: 0 auto;
      display: block; }
  #index .index-sns .list {
    padding: 20px 0 80px; }
    #index .index-sns .list ul {
      display: flex;
      justify-content: center;
      align-items: center; }
      #index .index-sns .list ul li {
        margin: 0 10px;
        text-align: center; }
        @media screen and (min-width: 769px) {
          #index .index-sns .list ul li {
            margin: 0 30px; } }
        #index .index-sns .list ul li p {
          font-size: 1.9rem;
          font-family: "Questrial", "Helvetica Neue", Helvetica, Arial, serif;
          letter-spacing: 0.2em;
          color: #99BA3D;
          margin-bottom: 10px; }
          @media screen and (min-width: 769px) {
            #index .index-sns .list ul li p {
              font-size: 2.0rem; } }
          #index .index-sns .list ul li p span {
            font-size: 1.2rem;
            color: #000;
            letter-spacing: 0;
            font-family: "Noto Sans JP", "Helvetica Neue", Helvetica, Arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif; }
        @media screen and (min-width: 769px) {
          #index .index-sns .list ul li a {
            opacity: 1;
            transition: all .2s ease; }
            #index .index-sns .list ul li a:hover {
              opacity: .5; } }
        #index .index-sns .list ul li a img {
          width: 50px;
          height: auto; }
          @media screen and (min-width: 769px) {
            #index .index-sns .list ul li a img {
              width: 60px; } }

#access {
  padding-top: 100px; }
  @media screen and (min-width: 430px) {
    #access {
      padding-top: 50px; } }
  #access .access-title {
    text-align: center;
    margin-bottom: 20px; }
    @media screen and (min-width: 430px) {
      #access .access-title {
        margin-bottom: 80px; } }
    #access .access-title img {
      width: 50%;
      max-width: 200px; }
      @media screen and (min-width: 430px) {
        #access .access-title img {
          width: auto;
          max-width: 100%; } }
  #access .access-info {
    padding: 0 15px; }
    @media screen and (min-width: 430px) {
      #access .access-info {
        padding: 0;
        width: 1200px;
        min-width: 1200px;
        margin: 0 auto 80px; } }
    @media screen and (min-width: 430px) {
      #access .access-info dl {
        display: flex;
        align-items: center; } }
    @media screen and (min-width: 430px) {
      #access .access-info dl dt {
        margin-right: 80px; } }
    #access .access-info dl dt img {
      width: 100%;
      height: auto; }
    #access .access-info dl dd {
      padding: 25px; }
      @media screen and (min-width: 430px) {
        #access .access-info dl dd {
          padding: 0; } }
      #access .access-info dl dd .tel {
        margin-bottom: 5px; }
        #access .access-info dl dd .tel a {
          text-decoration: none;
          color: #004D29;
          font-size: 3.2rem;
          font-family: "Questrial", "Helvetica Neue", Helvetica, Arial, serif; }
      #access .access-info dl dd .adr {
        color: #7F4F21;
        font-size: 1.35rem; }
        @media screen and (min-width: 430px) {
          #access .access-info dl dd .adr {
            font-size: 1.4rem;
            line-height: 2; } }
  #access .access-map {
    margin-bottom: 80px; }
    @media screen and (min-width: 430px) {
      #access .access-map {
        padding: 0;
        width: 1200px;
        min-width: 1200px;
        margin: 0 auto 80px; } }

#price {
  padding-top: 100px; }
  @media screen and (min-width: 430px) {
    #price {
      padding-top: 50px; } }
  #price .price-title {
    text-align: center;
    margin-bottom: 20px; }
    @media screen and (min-width: 430px) {
      #price .price-title {
        margin-bottom: 80px; } }
    #price .price-title img {
      width: 50%;
      max-width: 200px; }
      @media screen and (min-width: 430px) {
        #price .price-title img {
          width: auto;
          max-width: 100%; } }
  #price .price-menu {
    background-color: #FAF7F0;
    margin: 0 15px 50px;
    padding: 30px 20px; }
    @media screen and (min-width: 430px) {
      #price .price-menu {
        padding: 0;
        width: 1200px;
        min-width: 1200px;
        margin: 0 auto 80px; } }
    @media screen and (min-width: 430px) {
      #price .price-menu .inr {
        padding: 50px 80px; } }
    #price .price-menu .copy {
      margin-bottom: 20px; }
      @media screen and (min-width: 430px) {
        #price .price-menu .copy {
          margin-bottom: 50px; } }
      #price .price-menu .copy p {
        text-align: center;
        font-size: 1.8rem;
        color: #2C653B; }
        @media screen and (min-width: 430px) {
          #price .price-menu .copy p {
            font-size: 2.4rem;
            line-height: 2; } }
        #price .price-menu .copy p span {
          background: linear-gradient(transparent 70%, #FDF9C6 70%); }
    @media screen and (min-width: 430px) {
      #price .price-menu .list {
        width: 600px;
        margin: 0 auto 50px; } }
    #price .price-menu .list dl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.85rem;
      color: #2C653B;
      background-image: url(./assets/img/bg-dot.png);
      background-repeat: repeat-x;
      background-position: center center;
      background-size: auto 3px;
      margin-bottom: 10px; }
      @media screen and (min-width: 430px) {
        #price .price-menu .list dl {
          font-size: 2.6rem;
          margin-bottom: 20px; } }
      #price .price-menu .list dl dt, #price .price-menu .list dl dd {
        background: #FAF7F0; }
        @media screen and (min-width: 430px) {
          #price .price-menu .list dl dt, #price .price-menu .list dl dd {
            padding: 0 5px; } }
      #price .price-menu .list dl dd {
        font-family: "Questrial", "Helvetica Neue", Helvetica, Arial, serif; }
    #price .price-menu .text {
      margin-bottom: 30px; }
      @media screen and (min-width: 430px) {
        #price .price-menu .text {
          width: 800px;
          margin: 0 auto 80px; } }
      #price .price-menu .text p {
        color: #231815;
        font-size: 1.05rem;
        line-height: 2; }
        @media screen and (min-width: 430px) {
          #price .price-menu .text p {
            font-size: 1.4rem;
            letter-spacing: .05em; } }
    @media screen and (min-width: 430px) {
      #price .price-menu .list2 {
        width: 550px;
        margin: -20px auto 0; } }
    #price .price-menu .list2 dl {
      display: flex;
      flex-direction: column;
      font-size: 1.5rem;
      color: #2C653B;
      margin-bottom: 10px; }
      @media screen and (min-width: 430px) {
        #price .price-menu .list2 dl {
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-size: 2.0rem;
          margin-bottom: 20px; } }
      #price .price-menu .list2 dl dt {
        width: 120px;
        display: flex;
        justify-content: space-between; }
        @media screen and (min-width: 430px) {
          #price .price-menu .list2 dl dt {
            width: 160px;
            margin-right: 10px; } }
      #price .price-menu .list2 dl dd span {
        background: linear-gradient(transparent 70%, #FDF9C6 70%); }

/* ----------------------------------------------------------------
    blog list
-----------------------------------------------------------------*/
#archive-blog {
  padding-top: 0px; }
  @media screen and (max-width: 420px) {
    #archive-blog {
      padding-top: 75px; } }
  #archive-blog .archive-blog-header {
    max-width: 1200px;
    margin: 0 auto; }
    #archive-blog .archive-blog-header img {
      width: 100%;
      height: auto; }
    #archive-blog .archive-blog-header span {
      display: block;
      margin: 0 auto 30px;
      text-align: center; }
      #archive-blog .archive-blog-header span h2 {
        font-size: 3.0rem;
        color: #99BA3D;
        letter-spacing: 0.1em; }
        @media screen and (max-width: 420px) {
          #archive-blog .archive-blog-header span h2 {
            font-size: 2.0rem; } }
      #archive-blog .archive-blog-header span img {
        width: auto; }
        @media screen and (max-width: 420px) {
          #archive-blog .archive-blog-header span img {
            width: 100%;
            height: auto; } }
  #archive-blog .archive-blog-list .entry-wrap {
    display: flex;
    justify-content: flex-start;
    align-content: stretch;
    flex-wrap: wrap; }
    @media screen and (max-width: 420px) {
      #archive-blog .archive-blog-list .entry-wrap {
        display: flex; } }
    #archive-blog .archive-blog-list .entry-wrap .entry {
      width: 25%;
      padding: 10px;
      margin-bottom: 30px; }
      @media screen and (max-width: 420px) {
        #archive-blog .archive-blog-list .entry-wrap .entry {
          width: 50%;
          margin: 0;
          margin-bottom: 20px;
          padding: 5px; } }
      #archive-blog .archive-blog-list .entry-wrap .entry img {
        width: 100%;
        height: auto; }
      #archive-blog .archive-blog-list .entry-wrap .entry a {
        color: #2C653B;
        text-decoration: none; }

.tablenav {
  display: flex;
  justify-content: center;
  padding: 20px 0; }
  .tablenav a {
    color: #ccc;
    margin: 0px;
    line-height: 1;
    width: 35px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EEEEEE;
    transition: all .2s ease;
    text-decoration: none;
    font-size: 13px; }
    .tablenav a:hover {
      background-color: #99BA3D;
      color: #fff; }
    .tablenav a.next, .tablenav a.prev {
      width: 60px; }
    .tablenav a.next {
      border-radius: 0 20px 20px 0;
      padding-right: 10px; }
    .tablenav a.prev {
      border-radius: 20px 0 0 20px;
      padding-left: 10px; }
  .tablenav span {
    color: #fff;
    background-color: #99BA3D;
    line-height: 1;
    width: 35px;
    font-size: 13px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center; }

.archive-blog .inr {
  max-width: 1200px;
  margin: 0 auto; }

.archive-blog .archive-blog-nav {
  margin: 20px 0; }
  .archive-blog .archive-blog-nav p {
    font-size: 16px;
    text-align: center;
    margin-bottom: 30px; }

.archive-blog .archive-blog-list {
  padding: 20px 0; }
  @media screen and (max-width: 420px) {
    .archive-blog .archive-blog-list {
      padding: 20px 20px; } }
  .archive-blog .archive-blog-list article {
    transition: all 0.2s ease;
    opacity: 1; }
    .archive-blog .archive-blog-list article:hover {
      opacity: 0.5; }
    .archive-blog .archive-blog-list article h2 {
      font-size: 16px;
      font-weight: normal;
      font-family: "Noto Sans JP", "Helvetica Neue", Helvetica, Arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
      margin-bottom: 0px;
      text-decoration: underline; }
    .archive-blog .archive-blog-list article p {
      font-size: 12px;
      color: #000;
      margin-top: 5px; }

/*-----------------------------------------------------------------------------------
    sidebar
-----------------------------------------------------------------------------------*/
.side-nav {
  display: flex;
  justify-content: center; }
  @media screen and (max-width: 420px) {
    .side-nav {
      padding: 0px 20px;
      display: block; } }
  .side-nav .side-nav-col {
    width: 33%;
    margin: 0 10px; }
    @media screen and (max-width: 420px) {
      .side-nav .side-nav-col {
        width: 100%;
        margin: 10px 0; } }
    .side-nav .side-nav-col form {
      display: block;
      width: 100%; }
    .side-nav .side-nav-col select {
      background-color: #FAF7F0;
      display: block;
      width: 100%;
      padding: 10px 20px;
      position: relative; }
    .side-nav .side-nav-col label {
      position: relative;
      display: block; }
      .side-nav .side-nav-col label:after {
        content: "\f0dc";
        font-family: FontAwesome;
        position: absolute;
        top: 10px;
        right: 20px;
        display: block;
        pointer-events: none; }

/* ----------------------------------------------------------------
    blog list
-----------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------
    blog
-----------------------------------------------------------------------------------*/
#blog {
  padding-top: 0px; }
  @media screen and (max-width: 420px) {
    #blog {
      padding-top: 75px; } }
  #blog .blog-header {
    max-width: 1200px;
    margin: 0 auto; }
    #blog .blog-header img {
      width: 100%;
      height: auto; }
    #blog .blog-header span {
      display: block;
      margin: 0 auto 30px;
      text-align: center; }
      #blog .blog-header span h2 {
        font-size: 3.0rem;
        color: #99BA3D;
        letter-spacing: 0.1em; }
        @media screen and (max-width: 420px) {
          #blog .blog-header span h2 {
            font-size: 2.0rem; } }
      #blog .blog-header span img {
        width: auto; }
        @media screen and (max-width: 420px) {
          #blog .blog-header span img {
            width: 100%;
            height: auto; } }
  #blog .blog-list {
    background-color: #fef1d6; }
    #blog .blog-list .entry-wrap {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap; }
      @media screen and (max-width: 420px) {
        #blog .blog-list .entry-wrap {
          display: block; } }
      #blog .blog-list .entry-wrap .entry {
        width: 32%;
        margin-bottom: 30px; }
        @media screen and (max-width: 420px) {
          #blog .blog-list .entry-wrap .entry {
            width: 100%;
            margin-bottom: 20px; } }
        #blog .blog-list .entry-wrap .entry img {
          width: 100%;
          height: auto; }
        #blog .blog-list .entry-wrap .entry a {
          color: #2C653B; }

.blog .blog-nav {
  margin: 20px 0; }
  .blog .blog-nav .inr {
    max-width: 1200px;
    margin: 0 auto; }
  .blog .blog-nav p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 30px; }

.blog .blog-wrap .inr {
  max-width: 1200px;
  margin: 70px auto; }
  @media screen and (max-width: 420px) {
    .blog .blog-wrap .inr {
      padding: 10px;
      margin: 0; } }

.blog .blog-wrap article {
  background-color: #fff;
  padding: 20px 10px; }
  .blog .blog-wrap article h1 {
    display: block;
    border: 1px solid #99BA3D;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 .99C4 .445 4.444 0 5 0c.552 0 1 .45 1 .99v4.02C6 5.555 5.556 6 5 6c-.552 0-1-.45-1-.99V.99zm6 8c0-.546.444-.99 1-.99.552 0 1 .45 1 .99v4.02c0 .546-.444.99-1 .99-.552 0-1-.45-1-.99V8.99z' fill='%2399ba3d' fill-opacity='0.20' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-repeat: repeat;
    padding: 5px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #2C653B; }
    @media screen and (min-width: 430px) {
      .blog .blog-wrap article h1 {
        padding: 10px;
        font-size: 18px;
        margin-bottom: 15px; } }
  .blog .blog-wrap article .entry__desc {
    font-size: 14px;
    margin-bottom: 10px; }
    @media screen and (min-width: 430px) {
      .blog .blog-wrap article .entry__desc {
        margin-bottom: 20px; } }
    .blog .blog-wrap article .entry__desc p {
      margin-right: 10px;
      display: inline-block;
      margin-bottom: 0px; }
  .blog .blog-wrap article .entry__body p {
    font-size: 16px;
    line-height: 2;
    margin-bottom: 20px; }
  .blog .blog-wrap article .entry__body img {
    width: auto;
    height: auto;
    margin-bottom: 20px; }
  @media screen and (max-width: 420px) {
    .blog .blog-wrap article .entry__body img {
      width: 100%;
      height: auto;
      margin-bottom: 15px; }
    .blog .blog-wrap article .entry__body p {
      font-size: 13px;
      line-height: 2;
      margin-bottom: 15px; } }
  .blog .blog-wrap article .entry-nav {
    width: 690px;
    margin: 100px auto 0;
    text-align: center;
    display: flex;
    justify-content: center; }
    @media screen and (max-width: 420px) {
      .blog .blog-wrap article .entry-nav {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 0; } }
    .blog .blog-wrap article .entry-nav__btn {
      margin: 20px 10px;
      transition: all 0.2s ease; }
      @media screen and (max-width: 420px) {
        .blog .blog-wrap article .entry-nav__btn {
          margin: 10px 0px 0px;
          min-width: auto;
          display: block;
          width: 30%; } }
      .blog .blog-wrap article .entry-nav__btn:hover {
        opacity: 0.5; }
      .blog .blog-wrap article .entry-nav__btn a {
        border: 1px solid #2C653B;
        color: #2C653B;
        font-size: 14px;
        padding: 10px 20px;
        display: block;
        letter-spacing: 0.1em;
        text-decoration: none; }
        @media screen and (max-width: 420px) {
          .blog .blog-wrap article .entry-nav__btn a {
            cclor: #2C653B;
            font-size: 14px;
            display: block;
            padding: 10px;
            width: 100%; } }

#profile {
  padding-top: 100px; }
  @media screen and (min-width: 430px) {
    #profile {
      padding-top: 50px; } }
  #profile .profile-title {
    text-align: center;
    margin-bottom: 20px; }
    @media screen and (min-width: 430px) {
      #profile .profile-title {
        margin-bottom: 80px; } }
    #profile .profile-title img {
      width: 50%;
      max-width: 200px; }
      @media screen and (min-width: 430px) {
        #profile .profile-title img {
          width: auto;
          max-width: 100%;
          height: 85px; } }
  @media screen and (min-width: 430px) {
    #profile .profile-cover {
      padding: 0;
      width: 1200px;
      min-width: 1200px;
      margin: 0 auto 80px; } }
  #profile .profile-cover img {
    width: 100%;
    height: auto; }
  #profile .profile-text {
    margin-top: 40px; }
    @media screen and (min-width: 430px) {
      #profile .profile-text {
        padding: 0 5%;
        width: 1200px;
        min-width: 1200px;
        margin: 0 auto 80px; } }
    #profile .profile-text h2 {
      font-size: 18px;
      color: #2C653B;
      padding: 0 15px;
      font-weight: bold; }
      @media screen and (min-width: 430px) {
        #profile .profile-text h2 {
          font-size: 33px;
          padding: 0 40px; } }
    #profile .profile-text .profile-text-base {
      background-color: #FAF7F0;
      padding: 20px 15px;
      margin-top: 20px; }
      @media screen and (min-width: 430px) {
        #profile .profile-text .profile-text-base {
          padding: 40px;
          margin-top: 40px; } }
      #profile .profile-text .profile-text-base p {
        font-size: 12px;
        line-height: 1.8; }
        @media screen and (min-width: 430px) {
          #profile .profile-text .profile-text-base p {
            font-size: 16px;
            line-height: 2; } }
  @media screen and (min-width: 430px) {
    #profile .profile-image {
      padding: 0 5%;
      max-width: 1700px;
      width: 100%;
      min-width: 1200px;
      margin: 0 auto 80px;
      padding-left: 8%;
      margin-top: -100px;
      margin-bottom: -100px; } }
  #profile .profile-image img {
    max-width: 100%;
    height: auto; }
  #profile .profile-profile {
    margin-top: 40px;
    padding: 0 15px; }
    @media screen and (min-width: 430px) {
      #profile .profile-profile {
        padding: 0;
        width: 1200px;
        min-width: 1200px;
        margin: 200px auto 80px;
        background-image: url(./assets/img/profile5@2x.png);
        background-position: right top;
        background-size: 40% auto; } }
    #profile .profile-profile .profile-name {
      display: flex;
      flex-direction: column; }
      @media screen and (min-width: 430px) {
        #profile .profile-profile .profile-name {
          flex-direction: row; } }
      @media screen and (min-width: 430px) {
        #profile .profile-profile .profile-name dt {
          width: 45%; } }
      #profile .profile-profile .profile-name dt img {
        max-width: 100%;
        width: 100%;
        height: auto; }
      #profile .profile-profile .profile-name dd {
        margin-top: 15px; }
        @media screen and (min-width: 430px) {
          #profile .profile-profile .profile-name dd {
            width: 55%;
            margin-top: 0;
            padding-left: 2.5%;
            display: flex;
            justify-content: flex-end;
            flex-direction: column; } }
        #profile .profile-profile .profile-name dd h2 {
          font-size: 19px;
          font-weight: bold; }
          @media screen and (min-width: 430px) {
            #profile .profile-profile .profile-name dd h2 {
              font-size: 30px; } }
          #profile .profile-profile .profile-name dd h2 span {
            display: block;
            font-size: 10px;
            color: #2C653B; }
            @media screen and (min-width: 430px) {
              #profile .profile-profile .profile-name dd h2 span {
                font-size: 12px; } }
        #profile .profile-profile .profile-name dd p {
          font-size: 12px;
          margin-top: 5px; }
          @media screen and (min-width: 430px) {
            #profile .profile-profile .profile-name dd p {
              font-size: 15px; } }
    #profile .profile-profile .profile-career {
      margin-top: 30px; }
      @media screen and (min-width: 430px) {
        #profile .profile-profile .profile-career {
          margin-top: 50px; } }
      #profile .profile-profile .profile-career h2 {
        display: inline-block;
        color: #2C653B;
        font-size: 18px;
        letter-spacing: 0.1em;
        font-weight: bold;
        border-bottom: 2px solid #2C653B;
        margin-bottom: 20px; }
        @media screen and (min-width: 430px) {
          #profile .profile-profile .profile-career h2 {
            font-size: 24px;
            margin-bottom: 30px; } }
      #profile .profile-profile .profile-career p {
        font-size: 12px;
        border-bottom: 1px solid #000; }
        @media screen and (min-width: 430px) {
          #profile .profile-profile .profile-career p {
            font-size: 16px; } }
      #profile .profile-profile .profile-career dl {
        border-bottom: 1px solid #000;
        display: flex;
        padding: 10px 0; }
        @media screen and (min-width: 430px) {
          #profile .profile-profile .profile-career dl {
            padding: 15px 0; } }
        #profile .profile-profile .profile-career dl dt {
          font-size: 12px;
          width: 60px; }
          @media screen and (min-width: 430px) {
            #profile .profile-profile .profile-career dl dt {
              font-size: 16px;
              width: 100px; } }
        #profile .profile-profile .profile-career dl dd {
          font-size: 12px;
          width: calc(100% - 60px);
          padding-left: 20px; }
          @media screen and (min-width: 430px) {
            #profile .profile-profile .profile-career dl dd {
              font-size: 16px;
              width: calc(100% - 100px); } }
    #profile .profile-profile .profile-youtube {
      margin-top: 30px;
      margin-bottom: 100px; }
      @media screen and (min-width: 430px) {
        #profile .profile-profile .profile-youtube {
          margin-top: 50px; } }
      #profile .profile-profile .profile-youtube h2 {
        display: inline-block;
        color: #2C653B;
        font-size: 18px;
        letter-spacing: 0.1em;
        font-weight: bold;
        border-bottom: 2px solid #2C653B;
        margin-bottom: 20px; }
        @media screen and (min-width: 430px) {
          #profile .profile-profile .profile-youtube h2 {
            font-size: 24px;
            margin-bottom: 30px; } }
      #profile .profile-profile .profile-youtube dl {
        display: flex;
        flex-direction: column; }
        @media screen and (min-width: 430px) {
          #profile .profile-profile .profile-youtube dl {
            flex-direction: row;
            justify-content: space-between; } }
        #profile .profile-profile .profile-youtube dl dt {
          font-size: 12px; }
          @media screen and (min-width: 430px) {
            #profile .profile-profile .profile-youtube dl dt {
              margin-top: 20px;
              font-size: 16px;
              width: 50%; } }
        #profile .profile-profile .profile-youtube dl dd {
          margin-top: 20px; }
          @media screen and (min-width: 430px) {
            #profile .profile-profile .profile-youtube dl dd {
              margin-top: 0;
              width: 30%; } }
          #profile .profile-profile .profile-youtube dl dd img {
            width: 100%;
            height: auto; }
      #profile .profile-profile .profile-youtube .profile-button {
        margin-top: 20px; }
        #profile .profile-profile .profile-youtube .profile-button a {
          border: 1px solid #2C653B;
          font-size: 16px;
          color: #2C653B;
          text-decoration: none;
          padding: 5px 30px 5px 20px;
          position: relative;
          letter-spacing: 0.15em;
          line-height: 1; }
          #profile .profile-profile .profile-youtube .profile-button a::after {
            content: '';
            position: absolute;
            display: block;
            right: 10px;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 8px;
            height: 8px;
            border-top: 1px solid #2C653B;
            border-right: 1px solid #2C653B;
            transform: rotate(45deg); }
          #profile .profile-profile .profile-youtube .profile-button a:hover {
            background-color: #2C653B;
            color: #fff;
            transition: all .2s ease; }
            #profile .profile-profile .profile-youtube .profile-button a:hover::after {
              border-top: 1px solid #fff;
              border-right: 1px solid #fff; }
