.header {
  padding: 10px 15px;
  position: fixed;
  top:0;
  width: 100%;
  z-index: 999999;
  background-color: #fff;
  @include media(PC){
    position: relative;
    min-width: $common-width;
    width: $common-width;
    margin: 0 auto;
    padding: 20px 100px 35px;
  }
  .logo {
    width: 250px;
    @include media(PC){
      width: 430px;
      // margin: 0 auto;
      margin-top: 20px;
      margin-left: -100px;
    }
    a {
      display: block;
      dl {
        display: flex;
        align-items: center;
        @include media(PC){
          align-items: flex-start;
        }
        dt {
          width: 45px;
          line-height: 1;
          @include media(PC){
            width: auto;
            margin-bottom: 10px;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
        dd {
          width: 200px;
          line-height: 1;
          @include media(PC){
            width: auto;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  .gnavWrap {
    display: none;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 300px;
    max-width: 78%;
    background-color: $primary-color;
    font-size: 1.8rem;
    z-index: 99999;
    @include media(PC){
      display: inherit;
      max-width: 100%;
      width: $common-width - 300px;
      margin: 0 auto;
      top: 80px;
      right: 0;
      left: 0;
      background-color: rgba($primary-color, 0);
      z-index: 99999;
    }
    .gnav {
      padding: 60px 20px 40px;
      @include media(PC){
        padding: 0;
      }
      ul {
        @include media(PC){
          display: flex;
          margin-left: -30px;
        }
      }
      li {
        padding: 10px 20px;
        border-bottom: 1px solid rgba(#99BA3D,.2);
        @include media(PC){
          border-bottom: 0px solid rgba(#99BA3D,0);
          padding: 0;
          position: relative;
          &:after {
            content:"";
            width: 1;
            height: 40px;
            border-right: 1px solid $link-color;
            position: absolute;
            right: 0;
            top:0;
            bottom:0;
            transform: rotate(20deg);
            margin: auto;
          }
        }
        a {
          text-decoration: none;
          color: $light-color;
          display: block;
          @include media(PC){
            color: $link-color;
            font-size: 1.4rem;
            padding: 20px 35px;
            text-align: center;
            transition: all .2s ease;
            opacity: 1;
            &:hover {
              opacity: .5;
            }
          }
          span {
            display: none;
            font-family: $heading-font;
            font-size: 1.1rem;
            color: $primary-color;
            letter-spacing: .1em;
            @include media(PC){
              display: inherit;
            }
          }
        }
      }
    }
  }
  .header-contact {
    display: none;
    @include media(PC){
      display: inherit;
      position: absolute;
      right: 0;
      top: 25px;
      transition: all .2s ease;
      opacity: 1;
      &:hover {
        opacity: .8;
      }
    }
  }
}
.menu {
  position: absolute;
  right: 15px;
  top: 25px;
  height: 25px;
  width: 30px;
  z-index: 99999;
  span {
    position: absolute;
    bottom: 7px;
    margin: 0;
    padding: 0;
    img {
      width: 100%;
      height: auto;
    }
  }
  @include media(PC){
    display: none;
  }
}
.menu .menu-line {
position: absolute;
width: 30px;
left: 0px;
height: 5px;
// border-radius: 2px;
background: $primary-color;
}
 .menu .menu-line:nth-of-type(1) {
top: 0px;
-webkit-transition: all 0.3s;
transition: all 0.3s;
}
 .menu .menu-line:nth-of-type(2) {
top: 10px;
-webkit-transition: all 0.3s;
transition: all 0.3s;
}
 .menu .menu-line:nth-of-type(3) {
top: 20px;
-webkit-transition: all 0.3s;
transition: all 0.3s;
}
 .menu.open .menu-line {
background: $light-color;
}
 .menu.open .menu-line:nth-of-type(1) {
top: 10px;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
-webkit-transition: all 0.3s;
transition: all 0.3s;
}
 .menu.open .menu-line:nth-of-type(2) {
opacity: 0;
-webkit-transition: all 0.3s;
transition: all 0.3s;
}
 .menu.open .menu-line:nth-of-type(3) {
top: 10px;
-webkit-transform: rotate(-45deg);
-ms-transform: rotate(-45deg);
transform: rotate(-45deg);
-webkit-transition: all 0.3s;
transition: all 0.3s;
}


// homepage
.homepage{
  .header {
    @include media(PC){
      &:before {
        content:"";
        width: 500px;
        height: 200px;
        display: block;
        background-image: url(./assets/img/topkazari.png);
        background-repeat: no-repeat;
        background-size: 100% auto;
        position: absolute;
        left: -270px;
        top: 0px;
      }
    }
  }
  .logo {
    width: 250px;
    @include media(PC){
      width: 350px;
      margin: 0 auto;
    }
    a {
      display: block;
      dl {
        display: flex;
        align-items: center;
        @include media(PC){
          flex-direction: column;
        }
        dt {
          width: 45px;
          line-height: 1;
          @include media(PC){
            width: auto;
            margin-bottom: 10px;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
        dd {
          width: 200px;
          line-height: 1;
          @include media(PC){
            width: auto;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  .gnavWrap {
    @include media(PC){
      display: inherit;
      max-width: 100%;
      width: $common-width - 300px;
      margin: 0 auto;
      top: 720px;
      right: 0;
      left: 0;
      z-index: 99999;
      background-color: rgba($primary-color, 0);
    }
  }
}
