#index {
  @include media(PC){
    min-width: $common-width;
  }
  .index-slider {
    position: relative;
    margin-top: 80px;
    @include media(PC){
      margin-top: 0;
      min-width: $common-width;
      width: 100%;
    }
    .js-slider-col {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .menu-bg {
    position: absolute;
    bottom:3px;
    z-index: 99998;
    width: 100%;
    line-height: 1;
    vertical-align: bottom;
    margin-bottom: -2px;
    @include media(PC){
      bottom: 1px;
    }
    img {
      width: 100%;
      height: auto;
      line-height: 0;
      vertical-align: bottom;
    }
    @include media(PC){
    }
  }
  .index-concept {
    position: relative;
    z-index: 99999;
    @include media(PC){
      width: $common-width;
      min-width: $common-width;
      margin: 0 auto;
      padding: 100px 100px;
      position: relative;
      overflow: hidden;
      &:after {
        content:"";
        width: 850px;
        height: 60px;
        margin-top: 40px;
        background-image: url(./assets/img/concept_kazari.png);
        background-repeat: no-repeat;
        display: block;
        background-size: 100% auto;
        position: absolute;
        bottom: 120px;
        right: -60px;
        z-index: -1;
      }
    }
    .title {
      position: absolute;
      width: 80vw;
      top: -20vw;
      left: -8vw;
      @include media(PC){
        width: auto;
        top: 20px;
        left: auto;
        right: -80px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .text {
      padding-top: 15vw;
      @include media(PC){
        padding-top: 0;
      }
      dl {
        @include media(PC){
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        dt {
          @include media(PC){
            width: 420px;
          }
          &:after {
            content:"";
            width: 100%;
            height: 10vw;
            margin-top: 40px;
            background-image: url(./assets/img/concept_kazari.png);
            background-repeat: no-repeat;
            display: block;
            background-size: 100% auto;
            @include media(PC){
              display: none;
            }
          }
          img {
            width: 65%;
            max-width: 300px;
            margin: 0 auto;
            display: block;
            @include media(PC){
              width: 100%;
              max-width: 100%;
            }
          }
        }
        dd {
          padding: 15px;
          margin-bottom: 30px;
          @include media(PC){
            width: 500px;
            padding: 0;
            margin-bottom: 0;
            padding-top: 50px;
          }
          h3 {
            font-size: 1.8rem;
            color: $primary-color;
            font-weight: bold;
            margin-bottom: 15px;
            @include media(PC){
              font-size: 2.4rem;
              letter-spacing: -0.065em;
            }
          }
          p {
            font-size: 1.2rem;
            color: $link-color;
            text-align: justify;
            text-justify: inter-ideograph;
            @include media(PC){
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
  .index-condition {
    background-color: #FAF7F0;
    position: relative;
    padding: 30vw 15px 50px;
    @include media(PC){
      padding: 0;
      min-width: $common-width;
    }
    .inr {
      @include media(PC){
        width: $common-width;
        margin: 0 auto;
        padding: 100px;
        position: relative;
      }
    }
    .title {
      position: absolute;
      width: 80vw;
      top: -10vw;
      left: -8vw;
      @include media(PC){
        width: auto;
        top: -70px;
        left: 0;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .text {
      text-align: center;
      @include media(PC){
        padding-top: 80px;
        margin-bottom: 30px;
      }
      p {
        font-size: 1.6rem;
        color: $primary-color;
        line-height: 1.8;
        @include media(PC){
          font-size: 2.4rem;
        }
        &.strong {
          display: inline-block;
          font-size: 2.3rem;
          letter-spacing: -0.1em;
          margin-top: 5px;
          background: linear-gradient(transparent 70%, #FDF9C6 70%);
          @include media(PC){
            font-size: 3.0rem;
          }
        }
      }
    }
    .list {
      padding-top: 30px;
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-right: -2%;
        @include media(TB){
          margin-left: -1%;
        }
        li {
          width: 48%;
          border: 1px solid #99BA3D;
          background-color: #fff;
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 .99C4 .445 4.444 0 5 0c.552 0 1 .45 1 .99v4.02C6 5.555 5.556 6 5 6c-.552 0-1-.45-1-.99V.99zm6 8c0-.546.444-.99 1-.99.552 0 1 .45 1 .99v4.02c0 .546-.444.99-1 .99-.552 0-1-.45-1-.99V8.99z' fill='%2399ba3d' fill-opacity='0.20' fill-rule='evenodd'/%3E%3C/svg%3E");
          background-repeat: repeat;
          margin-right: 2%;
          margin-bottom: 15px;
          @include media(TB){
            width: 24%;
            margin-right: 1%;
          }
          a {
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
            padding: 15px 10px;
            text-decoration: none;
            color: $primary-color;
            font-size: 1.5rem;
            letter-spacing: -0.05em;
            position: relative;
            @include media(TB){
              padding: 15px 20px 15px 10px;
              font-size: 1.2rem;
            }
            @include media(PC){
              font-size: 2.4rem;
              padding: 20px;
              padding-right: 40px;
              opacity: 1;
              transition: all .2s ease;
              &:hover {
                opacity: .5;
              }
            }
            &:before {
              content:"";
              width: 15px;
              height: 15px;
              background-image: url(./assets/img/yajirushi.png);
              background-repeat: no-repeat;
              background-size: 100% auto;
              background-position: center center;
              display: inline-block;
              position: absolute;
              right: 5px;
              top: 0;
              bottom: 0;
              margin: auto;
              @include media(PC){
                width: 30px;
                height: 30px;
                right: 15px;
              }
            }
          }
        }
      }
    }
    .copy {
      font-size: 2.2rem;
      text-align: center;
      padding-top: 10px;
      letter-spacing: -0.09em;
      color: $primary-color;
      @include media(PC){
        font-size: 3.0rem;
        padding-top: 30px;
      }
    }
  }
  .index-point {
    padding: 15px;
    background-image: url(./assets/img/takumiback.jpg);
    background-repeat: repeat-y;
    background-size: 100% auto;
    position: relative;
    @include media(PC){
      padding: 0;
      background-image: url(./assets/img/yakusokuback-100.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%;
      // background-size: cover;
    }
    .inr {
      @include media(PC){
        width: $common-width;
        margin: 0 auto;
        position: relative;
      }
    }
    .title {
      width: 70%;
      max-width: 300px;
      position: absolute;
      top:0;
      right: 0;
      @include media(PC){
        width: 490px;
        max-width: 100%;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .list {
      padding-top: 28vw;
      @include media(PC){
        padding-top: 250px;
        padding-bottom: 30px;
      }
      ul {
        padding: 0 8%;
        @include media(PC){
          padding: 0 100px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        li {
          background-color: #fff;
          padding: 15px;
          margin-bottom: 20px;
          border: 1px solid $primary-color;
          @include media(PC){
            width: 31%;
            padding: 20px;
            margin-bottom: 40px;
          }
          .no {
            margin-bottom: 30px;
            padding-left: 10px;
            img {
              width: 100%;
              height: auto;
            }
          }
          .copy {
            font-family: serif;
            color: $primary-color;
            text-align: center;
            font-size: 2.5rem;
            line-height: 1.2;
            margin-bottom: 15px;
            @include media(PC){
              font-size: 2.9rem;
              margin-bottom: 20px;
              letter-spacing: -0.1em;
            }
          }
          .text {
            color: $link-color;
            font-size: 1.25rem;
            padding-bottom: 20px;
            text-align: justify;
            text-justify: inter-ideograph;
            @include media(PC){
              font-size: 1.4rem;
              padding-bottom: 30px;
            }
          }
        }
      }
    }
  }
  .index-blog {
    padding-bottom: 30px;
    @include media(PC){
      padding-bottom: 60px;
    }
    .title {
      width: 40%;
      max-width: 300px;
      margin: 0 auto 20px;
      padding-top: 30px;
      @include media(PC){
        padding-top: 70px;
        max-width: 250px;
        margin-bottom: 50px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .list {
      ul {
        padding: 0 25px;
        @include media(PC){
          padding: 0;
        }
        li {
          a {
            display: block;
            padding: 5px;
            text-decoration: none;
            color: #000;
            @include media(PC){
              padding: 0 20px;
              opacity: 1;
              transition: all .2s ease;
              &:hover {
                opacity: .5;
              }
            }
            .news-thumbnail {
              width: 100%;
              height: auto;
              margin-bottom: 10px;
            }
            .txt {
              .date {
                font-size: 1.0rem;
                @include media(PC){
                  font-size: 1.2rem;
                }
              }
              .post-name {
                font-size: 1.3rem;
                text-decoration: underline;
                color: $primary-color;
                margin-bottom: 10px;
                @include media(PC){
                  font-size: 1.6rem;
                }
              }
              .read {
                font-size: 1.1rem;
                @include media(PC){
                  font-size: 1.4rem;
                }
              }
            }
          }
        }
      }
      .slick-prev {
        left: 5px;
        z-index: 99999;
        font-size: 2.0rem;
        width: 30px;
        height: 30px;
        @include media(PC){
          left: 80px;
          width: 50px;
          height: 50px;
        }
      }
      .slick-next {
        right: 5px;
        z-index: 99999;
        width: 30px;
        height: 30px;
        @include media(PC){
          right: 80px;
          width: 50px;
          height: 50px;
        }
      }
      .slick-next:before, .slick-prev:before {
        font-family: slick;
        font-size: 30px;
        line-height: 1;
        color: $primary-color;
        opacity: .75;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @include media(PC){
          font-size: 50px;
        }
      }
    }
    .more {
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @include media(PC){
        padding: 60px 0;
      }
      .btn {
        border: 1px solid $primary-color;
        width: 120px;
        @include media(PC){
          width: 200px;
          opacity: 1;
          transition: all .2s ease;
          &:hover {
            opacity: .5;
          }
        }
        a {
          width: 100%;
          display: block;
          font-size: 1.4rem;
          padding: 5px 5px;
          text-decoration: none;
          text-align: center;
          color: $primary-color;
          position: relative;

          &:after {
            content: "\f105";
            position: absolute;
            font-family: fontAwesome;
            right: 10px;
          }
        }

      }
    }
  }
  .index-sns {
    background-color: #FAF7F0;
    padding: 15px;
    margin-bottom: -70px;
    @include media(PC){
      padding-bottom: 90px;
      margin-bottom: -150px;
    }
    .inr {
      @include media(PC){
        width: $common-width;
        margin: 40px auto;
        display: flex;
        align-items: center;
      }
    }
    .col2 {
      @include media(PC){
        display: flex;
        flex-direction: row-reverse;
        margin: 0 auto;
      }
    }
    .title {
      padding-top: 25px;
      @include media(PC){
        padding-top: 0;
        margin-left: 250px;
      }
      img {
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        display: block;
      }
    }
    .list {
      padding: 20px 0 80px;
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        li {
          margin: 0 10px;
          text-align: center;
          @include media(PC){
            margin: 0 30px;
          }
          p {
            font-size: 1.9rem;
            font-family: $heading-font;
            letter-spacing: 0.2em;
            color: $accent-color;
            margin-bottom: 10px;
            @include media(PC){
              font-size: 2.0rem;
            }
            span {
              font-size: 1.2rem;
              color: #000;
              letter-spacing: 0;
              font-family: $body-font;
            }
          }
          a {
            @include media(PC){
              opacity: 1;
              transition: all .2s ease;
              &:hover {
                opacity: .5;
              }
            }
            img {
              width: 50px;
              height: auto;
              @include media(PC){
                width: 60px;
              }
            }
          }
        }
      }
    }
  }
}
