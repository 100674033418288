html, body {
  font-size: 62.5%;
  font-family: $body-font;
  color: $dark-color;
  line-height: $line-height;
  -webkit-font-smoothing: antialiased;
  @include media(PC){
    min-width: $common-width;
  }
}

::selection {
  background: $primary-color;
  color: $light-color;
  text-shadow: none;
}

.sp-show {
display: inherit;
@include media(PC){
  display: none;
}
}

.pc-show {
display: none;
@include media(PC){
  display: inherit;
}
}
