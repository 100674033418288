/* ----------------------------------------------------------------
    blog list
-----------------------------------------------------------------*/
#archive-blog {
	padding-top:0px;
	@include media(TBM){
		padding-top: 75px;
	}
	.archive-blog-header {
		max-width: $common-width;
		margin: 0 auto;
		img {
			width: 100%;
			height:auto;
    }
    span {
      display: block;
      margin: 0 auto 30px;
      text-align: center;
      h2 {
        font-size: 3.0rem;
        color: $accent-color;
        letter-spacing: 0.1em;
        @include media(TBM){
          font-size: 2.0rem;
        }
      }
      img {
        width: auto;
        @include media(TBM){
          width: 100%;
          height: auto;
        }
      }
    }
	}
	.archive-blog-list {
		// background-color: #fef1d6;
		.entry-wrap {
			display: flex;
			justify-content: flex-start;
			align-content: stretch;
			flex-wrap: wrap;
			@include media(TBM){
        display: flex;
			}
			.entry {
        width: 25%;
        padding: 10px;
				margin-bottom: 30px;
				@include media(TBM){
					width: 50%;
					margin: 0;
          margin-bottom: 20px;
          padding: 5px;
				}
				img {
					width: 100%;
					height:auto;
				}
				a {
          color: $primary-color;
          text-decoration: none;
				}
			}
		}
	}
}

.tablenav {
	display: flex;
	justify-content: center;
	padding: 20px 0;
	a {
		color: #ccc;
		margin: 0px;
		line-height: 1;
		width: 35px;
		height: 40px;
		display: flex;
		justify-content: center;
    align-items: center;
    background-color: #EEEEEE;
    transition: all .2s ease;
    text-decoration: none;
    font-size: 13px;
		&:hover {
    background-color: $accent-color;
    color: #fff;
    }
    &.next, &.prev{
      width: 60px;
    }
    &.next {
      border-radius: 0 20px 20px 0;
      padding-right: 10px;
    }
    &.prev {
      border-radius: 20px 0 0 20px;
      padding-left: 10px;
    }
	}
	span {
    color: #fff;
    background-color: $accent-color;
		line-height: 1;
    width: 35px;
    font-size: 13px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.archive-blog {
	.inr {
		max-width: $common-width;
		margin: 0 auto;
	}
	.archive-blog-nav {
		margin: 20px 0;
		p {
			font-size: 16px;
			text-align: center;
			margin-bottom:30px;
		}
	}

	.archive-blog-list {
		padding: 20px 0;

		@include media(TBM){
			padding: 20px 20px;
		}

		article {
      transition: all 0.2s ease;
      opacity: 1;
			@include media(TBM){
			}
			&:hover {
				opacity: 0.5;
			}

			h2 {
				// padding: 20px;
				font-size: 16px;
				font-weight: normal;
				font-family: $body-font;
        margin-bottom: 0px;
        text-decoration: underline;
			}
			p {
				font-size: 12px;
        color: #000;
        margin-top: 5px;
			}
		}
	}
}


/*-----------------------------------------------------------------------------------
    sidebar
-----------------------------------------------------------------------------------*/

.side-nav {
	display: flex;
	justify-content: center;
	@include media(TBM){
		padding: 0px 20px;
		display: block;
	}
	.side-nav-col {
		width: 33%;
		margin: 0 10px;
		@include media(TBM){
			width: 100%;
			margin: 10px 0 ;
		}

		form {
			display: block;
			width:100%;
		}

		select {
			background-color: $lightbg-color;
			display: block;
			width:100%;
			padding: 10px 20px;
			position: relative;
		}
		label{
			position: relative;
			display: block;

			&:after{
				content:"\f0dc";
				font-family: FontAwesome;
				position: absolute;
				top: 10px;
				right: 20px;
				display: block;
				pointer-events: none;
			}
		}
	}
}

/* ----------------------------------------------------------------
    blog list
-----------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------
    blog
-----------------------------------------------------------------------------------*/
#blog{
	padding-top:0px;
	@include media(TBM){
		padding-top: 75px;
	}
	.blog-header {
    max-width: $common-width;
		margin: 0 auto;
		img {
			width: 100%;
			height:auto;
    }
    span {
      display: block;
      margin: 0 auto 30px;
      text-align: center;
      h2 {
        font-size: 3.0rem;
        color: $accent-color;
        letter-spacing: 0.1em;
        @include media(TBM){
          font-size: 2.0rem;
        }
      }      img {
        width: auto;
        @include media(TBM){
          width: 100%;
          height: auto;
        }
      }
    }
	}
	.blog-list {
		background-color: #fef1d6;
		.entry-wrap {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			@include media(TBM){
				display: block;
			}
			.entry {
				width: 32%;
				margin-bottom: 30px;
				@include media(TBM){
					width: 100%;
					margin-bottom: 20px;
				}
				img {
					width: 100%;
					height:auto;
				}
				a {
					color: $primary-color;
				}
			}
		}
	}
}

.blog {
	.blog-nav {
		margin: 20px 0;
		.inr{
			max-width: $common-width;
			margin: 0 auto;
		}
		p {
			font-size: 14px;
			text-align: center;
			margin-bottom: 30px;
		}
	}
	.blog-wrap {
		// background-color: #dee7f0;
		.inr {
			max-width: $common-width;
			margin: 70px auto;
			@include media(TBM){
        padding: 10px;
        margin: 0;
			}
		}

		article {
			background-color: #fff;
			padding: 20px 10px;

			h1 {
				display: block;
				border: 1px solid #99BA3D;
          background-color: #fff;
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 .99C4 .445 4.444 0 5 0c.552 0 1 .45 1 .99v4.02C6 5.555 5.556 6 5 6c-.552 0-1-.45-1-.99V.99zm6 8c0-.546.444-.99 1-.99.552 0 1 .45 1 .99v4.02c0 .546-.444.99-1 .99-.552 0-1-.45-1-.99V8.99z' fill='%2399ba3d' fill-opacity='0.20' fill-rule='evenodd'/%3E%3C/svg%3E");
          background-repeat: repeat;
				padding: 5px;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        color: $primary-color;
        @include media(TB){
          padding: 10px;
          font-size: 18px;
          margin-bottom: 15px;
        }
			}

			.entry__desc {
				font-size: 14px;
        margin-bottom: 10px;
        @include media(TB){
          margin-bottom: 20px;
        }

				p {
					margin-right: 10px;
					display: inline-block;
					margin-bottom: 0px;
				}
			}
			.entry__body {
        p {
          font-size: 16px;
          line-height: 2;
          margin-bottom: 20px;
        }
        img {
          width: auto;
          height: auto;
          margin-bottom: 20px;
        }
				@include media(TBM){
					img {
						width: 100%;
            height: auto;
            margin-bottom: 15px;
          }
          p {
            font-size: 13px;
            line-height: 2;
            margin-bottom: 15px;
          }
				}
			}

			.entry-nav {
				width: 690px;
				margin: 100px auto 0;
				text-align: center;
        display: flex;
        justify-content: center;
				@include media(TBM){
					width: 100%;
					display: flex;
          justify-content: space-between;
          margin: 0;
				}

				&__btn {
					margin:20px 10px;
					transition: all 0.2s ease;
					@include media(TBM){
						margin: 10px 0px 0px;
						min-width: auto;
						display: block;
						width: 30%;
					}

					&:hover {
						opacity: 0.5;
					}

					a {
            border: 1px solid $primary-color;
						color: $primary-color;
						font-size: 14px;
						padding: 10px 20px;
            display: block;
            // font-weight: bold;
            letter-spacing: 0.1em;
            text-decoration: none;
						@include media(TBM){
              cclor: $primary-color;
              font-size: 14px;
              display: block;
              padding: 10px;
              width: 100%;
						}
					}
				}
			}

		}
	}
}
