//-----------------------------------------------------------------------------------
//
//  config.scss
//
//  1. Base Colors
//  2. Line Height Variables
//  3. Margins
//  4. Font Families
//  5. Breakpoint Setting
//
//-----------------------------------------------------------------------------------

//////////////////////////////////////////////////////////
// 1. Base Colors
//////////////////////////////////////////////////////////

$primary-color:     #2C653B;
$accent-color:      #99BA3D;
$light-color:       #fff;
$dark-color:        #131313;
$lightbg-color:      #FAF7F0;
$link-color:      #7F4F21;

//////////////////////////////////////////////////////////
// 2. Line Height Variables
//////////////////////////////////////////////////////////

$line-height:              1.6;

//////////////////////////////////////////////////////////
// 3. Margins
//////////////////////////////////////////////////////////

$margin:       15px;

//////////////////////////////////////////////////////////
// 4. Font Families
//////////////////////////////////////////////////////////

@import url('https://fonts.googleapis.com/css?family=Questrial');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700');

$body-font: "Noto Sans JP", "Helvetica Neue", Helvetica, Arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$heading-font: 'Questrial', "Helvetica Neue", Helvetica, Arial, serif;


//////////////////////////////////////////////////////////
// 5. Breakpoint Setting
//////////////////////////////////////////////////////////

$breakpoints: (
    'TB': 'screen and (min-width: 430px)',
    'PC': 'screen and (min-width: 769px)',
    'LG': 'screen and (min-width: 1200px)',
    'TBM': 'screen and (max-width: 420px)',
    'PCM': 'screen and (max-width: 768px)',
    'LGM': 'screen and (max-width: 1199px)'
) !default;


//////////////////////////////////////////////////////////
// 6. layout
//////////////////////////////////////////////////////////

$common-width: 1200px;
