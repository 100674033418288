#profile {
  padding-top: 100px;
  @include media(TB){
    padding-top: 50px;
  }
  .profile-title {
    text-align: center;
    margin-bottom: 20px;
    @include media(TB){
      margin-bottom: 80px;
    }
    img {
      width: 50%;
      max-width: 200px;
      @include media(TB){
        width: auto;
        max-width: 100%;
        height: 85px;
      }
    }
  }

  .profile-cover {
    @include media(TB){
      padding: 0;
      width: $common-width;
      min-width: $common-width;
      margin: 0 auto 80px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  .profile-text {
    margin-top: 40px;

    @include media(TB){
      padding: 0 5%;
      width: $common-width;
      min-width: $common-width;
      margin: 0 auto 80px;
    }

    h2 {
      font-size: 18px;
      color: $primary-color;
      padding: 0 15px;
      font-weight: bold;

      @include media(TB){
        font-size: 33px;
        padding: 0 40px;
      }
    }

    .profile-text-base {
      background-color: #FAF7F0;
      padding: 20px 15px;
      margin-top: 20px;

      @include media(TB){
        padding: 40px;
        margin-top: 40px;
      }

      p {
        font-size: 12px;
        line-height: 1.8;

        @include media(TB){
          font-size: 16px;
          line-height: 2;
        }
      }
    }
  }

  .profile-image {
    @include media(TB){
      padding: 0 5%;
      max-width:  $common-width + 500px;
      width: 100%;
      min-width: $common-width;
      margin: 0 auto 80px;
      padding-left: 8%;
      margin-top: -100px;
      margin-bottom: -100px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }

  .profile-profile {
    margin-top: 40px;
    padding: 0 15px;

    @include media(TB){
      padding: 0;
      width: $common-width;
      min-width: $common-width;
      margin: 200px auto 80px;

      background-image: url(./assets/img/profile5@2x.png);
      background-position: right top;
      background-size: 40% auto;
    }

    .profile-name {
      display: flex;
      flex-direction: column;

      @include media(TB){
        flex-direction: row;
      }

      dt {
        @include media(TB){
          width: 45%;
        }
        img {
          max-width: 100%;
          width: 100%;
          height: auto;
        }
      }

      dd {
        margin-top: 15px;

        @include media(TB){
          width: 55%;
          margin-top: 0;
          padding-left: 2.5%;
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
        }

        h2 {
          font-size: 19px;
          font-weight: bold;

          @include media(TB){
            font-size: 30px;
          }

          span {
            display: block;
            font-size: 10px;
            color: $primary-color;

            @include media(TB){
              font-size: 12px;
            }
          }
        }

        p {
          font-size: 12px;
          margin-top: 5px;

          @include media(TB){
            font-size: 15px;
          }
        }
      }
    }

    .profile-career {
      margin-top: 30px;

      @include media(TB){
        margin-top: 50px;
      }

      h2 {
        display: inline-block;
        color: $primary-color;
        font-size: 18px;
        letter-spacing: 0.1em;
        font-weight: bold;
        border-bottom: 2px solid $primary-color;
        margin-bottom: 20px;

        @include media(TB){
          font-size: 24px;
          margin-bottom: 30px;
        }
      }

      p {
        font-size: 12px;
        border-bottom: 1px solid #000;

        @include media(TB){
          font-size: 16px;
        }
      }

      dl {
        border-bottom: 1px solid #000;
        display: flex;
        padding: 10px 0;

        @include media(TB){
          padding: 15px 0;
        }

        dt {
          font-size: 12px;
          width: 60px;

          @include media(TB){
            font-size: 16px;
            width: 100px;
          }
        }

        dd {
          font-size: 12px;
          width: calc(100% - 60px);
          padding-left: 20px;

          @include media(TB){
            font-size: 16px;
            width: calc(100% - 100px);
          }
        }
      }
    }
    .profile-youtube {
      margin-top: 30px;
      margin-bottom: 100px;

      @include media(TB){
        margin-top: 50px;
      }

      h2 {
        display: inline-block;
        color: $primary-color;
        font-size: 18px;
        letter-spacing: 0.1em;
        font-weight: bold;
        border-bottom: 2px solid $primary-color;
        margin-bottom: 20px;

        @include media(TB){
          font-size: 24px;
          margin-bottom: 30px;
        }
      }

      dl {
        display: flex;
        flex-direction: column;

        @include media(TB){
          flex-direction: row;
          justify-content: space-between;
        }
        dt {
          font-size: 12px;
          @include media(TB){
            margin-top: 20px;
            font-size: 16px;
            width: 50%;
          }
        }
        dd {
          margin-top: 20px;

          @include media(TB){
            margin-top: 0;
            width: 30%;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
      }

      .profile-button {
        margin-top: 20px;

        a {
          border: 1px solid $primary-color;
          font-size: 16px;
          color: $primary-color;
          text-decoration: none;
          padding: 5px 30px 5px 20px;
          position: relative;
          letter-spacing: 0.15em;
          line-height: 1;

          &::after{
            content: '';
            position: absolute;
            display: block;
            right: 10px;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 8px;
            height: 8px;
            border-top: 1px solid $primary-color;
            border-right: 1px solid $primary-color;
            transform: rotate(45deg);
          }

          &:hover {
            background-color: $primary-color;
            color: #fff;
            transition: all .2s ease;

            &::after{
              border-top: 1px solid #fff;
              border-right: 1px solid #fff;
            }
          }
        }
      }
    }
  }
}
