
#access {
  padding-top: 100px;
  @include media(TB){
    padding-top: 50px;
  }
  .access-title {
    text-align: center;
    margin-bottom: 20px;
    @include media(TB){
      margin-bottom: 80px;
    }
    img {
      width: 50%;
      max-width: 200px;
      @include media(TB){
        width: auto;
        max-width: 100%;
      }
    }
  }
  .access-info {
    padding: 0 15px;
    @include media(TB){
      padding: 0;
      width: $common-width;
      min-width: $common-width;
      margin: 0 auto 80px;
    }
    dl {
      @include media(TB){
        display: flex;
        align-items: center;

      }
      dt {
        @include media(TB){
          margin-right: 80px;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      dd {
        padding:25px;
        @include media(TB){
          padding: 0;
        }
        .tel {
          margin-bottom: 5px;
          a {
            text-decoration: none;
            color: #004D29;
            font-size: 3.2rem;
            font-family: $heading-font;
          }
        }
        .adr {
          color: $link-color;
          font-size: 1.35rem;
          @include media(TB){
            font-size: 1.4rem;
            line-height: 2;
          }
        }
      }
    }
  }
  .access-map {
    margin-bottom: 80px;
    @include media(TB){
      padding: 0;
      width: $common-width;
      min-width: $common-width;
      margin: 0 auto 80px;
    }
  }
}
